import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout/index.vue'
import Login from '@/views/login/index.vue'
import Home from '@/views/home/Home.vue'
import store from '../store'
// import product from '@/views/Product/index.vue'
const Product1 = () => import('@/views/Product/index.vue')
const List1 = () => import('@/views/Product/list/index.vue')
const Need = () => import('@/views/Need/index.vue')
const Feedback = () => import('@/views/Need/Feedback/index.vue')
const Enterprise = () => import('@/views/Need/Enterprise/index.vue')
const Inquiry = () => import('@/views/Need/Inquiry/index.vue')
const Locked = () => import('@/views/Need/Locked/index.vue')
const NeedCenter = () => import('@/views/Need/needCenter/index.vue')
const Payment = () => import('@/views/Need/Payment/index.vue')
const Pending = () => import('@/views/Need/Pending/index.vue')
const Personal = () => import('@/views/Need/Personal/index.vue')
const Asset = () => import('@/views/Need/Asset/index.vue')
const Report = () => import('@/views/Need/Report/index.vue')
const Review = () => import('@/views/Need/Review/index.vue')
const Shipped = () => import('@/views/Need/Shipped/index.vue')
const Request = () => import('@/views/Need/Request/index.vue')
const ProcessingDetails = () => import('@/views/Need/ProcessingDetails/index.vue')
const Recruit = () => import('@/views/Need/Recruit/index.vue')
const RecruitmentDetails = () => import('@/views/Need/RecruitmentDetails/index.vue')

const Crm = () => import('@/views/Crm/index.vue')
const AICWS = () => import('@/views/Crm/AICWS/index.vue')
const SetUp = () => import('@/views/Crm/SetUp/index.vue')
const DeleteList = () => import('@/views/Crm/DeleteList/index.vue')
const DeleteRecord = () => import('@/views/Crm/DeleteRecord/index.vue')
const Publish = () => import('@/views/Crm/Publish/index.vue')



const FollowUpRecords = () => import('@/views/Crm/FollowUpRecords/index.vue')
const CustomerList = () => import('@/views/Crm/CustomerList/index.vue')
const DataPacket = () => import('@/views/Crm/DataPacket/index.vue')
const HighSeasData = () => import('@/views/Crm/HighSeasData/index.vue')
const AccurateData = () => import('@/views/Crm/AccurateData/index.vue')
const RecycleBin = () => import('@/views/Crm/RecycleBin/index.vue')
const ImExPort = () => import('@/views/Crm/ImExPort/index.vue')
const HighSeasDataDetails = () => import('@/views/Crm/HighSeasDataDetails/index.vue')
const DuplicateData = () => import('@/views/Crm/DuplicateData/index.vue')
const Repetitions = () => import('@/views/Crm/Repetitions/index.vue')
const DuplicateDetails = () => import('@/views/Crm/DuplicateDetails/index.vue')
const Offline = () => import('@/views/Crm/Offline/index.vue')
const UserDataCenter = () => import('@/views/UserDataCenter/index.vue')
const AllMerchants = () => import('@/views/UserDataCenter/AllMerchants/index.vue')
const Video = () => import('@/views/UserDataCenter/Video/index.vue')
const Album = () => import('@/views/UserDataCenter/Album/index.vue')
const NewMachineEnterpris = () => import('@/views/UserDataCenter/NewMachineEnterpris/index.vue')
const NewMachinePersonal = () => import('@/views/UserDataCenter/NewMachinePersonal/index.vue')
const SecondHandPerson = () => import('@/views/UserDataCenter/SecondHandPerson/index.vue')
const SecondHandEnterprise = () => import('@/views/UserDataCenter/SecondHandEnterprise/index.vue')
const TextileFactoryIndividual = () => import('@/views/UserDataCenter/TextileFactoryIndividual/index.vue')
const TextileFactoryEnterprise = () => import('@/views/UserDataCenter/TextileFactoryEnterprise/index.vue')
const FollowUp = () => import('@/views/UserDataCenter/FollowUp/index.vue')
const EnterpriseCentre = () => import('@/views/UserDataCenter/EnterpriseCentre/index.vue')
const PersonalCenter = () => import('@/views/UserDataCenter/PersonalCenter/index.vue')
const Personaldetails = () => import('@/views/UserDataCenter/Personaldetails/index.vue')
const Enterpriseldetails = () => import('@/views/UserDataCenter/Enterpriseldetails/index.vue')
const User = () => import('@/views/UserDataCenter/User/index.vue')

const UserDetails = () => import('@/views/UserDataCenter/UserDetails/index.vue')


const VirtualUsers = () => import('@/views/UserDataCenter/VirtualUsers/index.vue')
const MyTeam = () => import('@/views/UserDataCenter/MyTeam/index.vue')
const Goods = () => import('@/views/Goods/index.vue')
const Product = () => import('@/views/Goods/Product/index.vue')
const Release = () => import('@/views/Goods/Release/index.vue')
const Release1 = () => import('@/views/Goods/Release1/index.vue')
const Parameter = () => import('@/views/Goods/Parameter/index.vue')
const List = () => import('@/views/Goods/List/index.vue')
const Details = () => import('@/views/Goods/Details/index.vue')
const Center = () => import('@/views/Goods/Center/index.vue')
const OrderM = () => import('@/views/OrderM/index.vue')
const OrderList = () => import('@/views/OrderM/OrderList/index.vue')
const OpinionContract = () => import('@/views/OrderM/OpinionContract/index.vue')
const OrderDetails = () => import('@/views/OrderM/OrderDetails/index.vue')
const Settings = () => import('@/views/Settings/index.vue')
const Arguments = () => import('@/views/Settings/Arguments/index.vue')
const Links = () => import('@/views/Settings/Links/index.vue')
const Ranking = () => import('@/views/Settings/Ranking/index.vue')
const Menu = () => import('@/views/Settings/Menu/index.vue')
const Site = () => import('@/views/Settings/Site/index.vue')
const INCOTERMS = () => import('@/views/Settings/INCOTERMS/index.vue')
const editINCOTERMS = () => import('@/views/Settings/editINCOTERMS/index.vue')
const country = () => import('@/views/Settings/Country/index.vue')
const ReviewTemplate = () => import('@/views/Settings/ReviewTemplate/index.vue')
const SiteTemplate = () => import("@/views/Settings/SiteTemplate/index.vue");
const logistics = () => import('@/views/Settings/logistics/index.vue')
const shopseo = () => import('@/views/Settings/shopseo/index.vue')
const AppVersion = () => import('@/views/Settings/AppVersion/index.vue')
const RegionManagement = () => import('@/views/Settings/RegionManagement/index.vue')

const Operate = () => import('@/views/Operate/index.vue')
const Records = () => import('@/views/Operate/Records/index.vue')
const footprint = () => import('@/views/Operate/footprint/index.vue')
const Behavior = () => import('@/views/Operate/Behavior/index.vue')
const report = () => import('@/views/Operate/report/index.vue')
const feedback = () => import('@/views/Operate/feedback/index.vue')
const Message = () => import('@/views/Operate/Message/index.vue')
const inquiry = () => import('@/views/Operate/inquiry/index.vue')
const Recruiting = () => import('@/views/Operate/Recruiting/index.vue')

const Content = () => import('@/views/Content/index.vue')
const Classify = () => import('@/views/Content/Classify/index.vue')
const Listing = () => import('@/views/Content/Listing/index.vue')
const Notice = () => import('@/views/Content/Notice/index.vue')
const Category = () => import('@/views/Content/Category/index.vue')
const RecycleBinS = () => import('@/views/Content/RecycleBin/index.vue')
const Contents = () => import('@/views/Content/Content/index.vue')
const Management = () => import('@/views/Management/index.vue')
const Diary = () => import('@/views/Management/Diary/index.vue')
const Managers = () => import('@/views/Management/Managers/index.vue')
const Organizational = () => import('@/views/Management/Organizational/index.vue')
const Role = () => import('@/views/Management/Role/index.vue')
const disposal = () => import('@/views/Operate/disposal/index.vue')
const purchase = () => import('@/views/Operate/purchase/index.vue')
const Brand = () => import('@/views/Operate/Brand/index.vue')
const Banner = () => import('@/views/Operate/Banner/index.vue')
const Space = () => import('@/views/Operate/Space/index.vue')
const advertising = () => import('@/views/Operate/advertising/index.vue')
const DisposalDetails = () => import('@/views/Operate/DisposalDetails/index.vue')
const PurchaseDetails = () => import('@/views/Operate/PurchaseDetails/index.vue')
const CustomerService = () => import('@/views/Operate/CustomerService/index.vue')
const CustomerSllocation = () => import('@/views/Operate/CustomerSllocation/index.vue')
const MarketingActivities = () => import('@/views/Operate/MarketingActivities/index.vue')


const Online = () => import('@/views/Operate/Online/index.vue')
const Userd = () => import('@/views/User/index.vue')
const AlbumVideo = () => import('@/views/User/AlbumVideo/index.vue')
const DealerList = () => import('@/views/User/DealerList/index.vue')
const UserSettings = () => import('@/views/User/UserSettings/index.vue')
const Custom = () => import('@/views/User/Custom/index.vue')
const parameters = () => import('@/views/Goods/parameters/index.vue')
const Foreigntrade = () => import('@/views/OrderM/Foreigntrade/index.vue')
const FOrderDetails = () => import('@/views/OrderM/FOrderDetails/index.vue')
const Financial = () => import('@/views/Financial/index.vue')
const CompanyTitle = () => import('@/views/Financial/CompanyTitle/index.vue')
const PaymentAccount = () => import('@/views/Financial/PaymentAccount/index.vue')
const DocumentType = () => import('@/views/Financial/DocumentType/index.vue')
const PaymentAddition = () => import('@/views/Financial/PaymentAddition/index.vue')
// PaymentAddition
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    meta: {
      title: '首页',
      isLogin: true,
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: Home
      }, {
        path: '/product1',
        name: 'product1',
        component: Product1,
        children: [
          {
            path: 'list1',
            name: 'list1',
            component: List1
          },
        ]
      },
      {
        path: '/need',
        name: 'need',
        component: Need,
        children: [
          {//待办中心
            path: 'needCenter',
            name: 'needCenter',
            component: NeedCenter
          },
          {//企业认证
            path: 'Enterprise',
            name: 'Enterprise',
            component: Enterprise
          },
          {//个人认证
            path: 'Personal',
            name: 'Personal',
            component: Personal
          },

          {//询价中心待处理
            path: 'Inquiry',
            name: 'Inquiry',
            component: Inquiry
          },
          {//资产处置待处理
            path: 'Asset',
            name: 'Asset',
            component: Asset
          },
          {//商家商品待审
            path: 'Review',
            name: 'Review',
            component: Review
          },
          {//我的举报待处理
            path: 'Report',
            name: 'Report',
            component: Report
          },
          {//用户反馈待处理
            path: 'Feedback',
            name: 'Feedback',
            component: Feedback
          },
          {//订单列表--待锁定
            path: 'Locked',
            name: 'Locked',
            component: Locked
          },
          {//订单列表--待支付
            path: 'Payment',
            name: 'Payment',
            component: Payment
          },
          {//订单列表--待发货
            path: 'Pending',
            name: 'Pending',
            component: Pending
          },
          {//订单列表--已发货
            path: 'Shipped',
            name: 'Shipped',
            component: Shipped
          },
          {//我的求购未处理
            path: 'Request',
            name: 'Request',
            component: Request
          },
          {//处理详情
            path: 'ProcessingDetails',
            name: 'ProcessingDetails',
            component: ProcessingDetails
          },
           {//我要招聘
            path: 'Recruit',
            name: 'Recruit',
            component: Recruit
          },
           {//我要招聘
            path: 'RecruitmentDetails',
            name: 'RecruitmentDetails',
            component: RecruitmentDetails
          },
          
          
          
          

        ]
      },
      {
        path: '/Crm',
        name: 'Crm',
        component: Crm,
        children: [
          {
            //AI文案设置
            path: 'AICWS',
            name: 'AICWS',
            component: AICWS
          },
          {
            //设置
            path: 'SetUp',
            name: 'SetUp',
            component: SetUp
          },
          {
            //删除列表
            path: 'DeleteList',
            name: 'DeleteList',
            component: DeleteList
          },
          {
            //删除列表
            path: 'DeleteRecord',
            name: 'DeleteRecord',
            component: DeleteRecord
          },
          
          {
            //跟进记录
            path: 'FollowUpRecords',
            name: 'FollowUpRecords',
            component: FollowUpRecords
          },
          {
            //数据分组
            path: 'CustomerList',
            name: 'CustomerList',
            component: CustomerList
          },

          {//数据分组
            path: 'DataPacket',
            name: 'DataPacket',
            component: DataPacket
          },
          {//公海数据
            path: 'HighSeasData',
            name: 'HighSeasData',
            component: HighSeasData
          },
          {//精准数据
            path: 'AccurateData',
            name: 'AccurateData',
            component: AccurateData
          },
          {//回收站
            path: 'RecycleBin',
            name: 'RecycleBin',
            component: RecycleBin
          },
          {//导入导出记录
            path: 'ImExPort',
            name: 'ImExPort',
            component: ImExPort
          },
          {//公海数据详情
            path: 'HighSeasDataDetails',
            name: 'HighSeasDataDetails',
            component: HighSeasDataDetails
          },
          {//匹配不成功数据
            path: 'DuplicateData',
            name: 'DuplicateData',
            component: DuplicateData
          },

          {//重复数据
            path: 'Repetitions',
            name: 'Repetitions',
            component: Repetitions
          },
          {//重复数据详情
            path: 'DuplicateDetails',
            name: 'DuplicateDetails',
            component: DuplicateDetails
          },
          {//线下商机
            path: 'Offline',
            name: 'Offline',
            component: Offline
          },
          {//发布资产处置发布和我的求购发布
            path: 'Publish',
            name: 'Publish',
            component: Publish
          },



          
        ]
      }, {
        path: '/UserDataCenter',
        name: 'UserDataCenter',
        component: UserDataCenter,
        children: [
          {//企业视频
            path: 'Video',
            name: 'Video',
            component: Video
          },
          {//企业相册
            path: 'Album',
            name: 'Album',
            component: Album
          },
          {//企业品牌新机
            path: 'NewMachineEnterpris',
            name: 'NewMachineEnterpris',
            component: NewMachineEnterpris
          },
          {//个人品牌新机
            path: 'NewMachinePersonal',
            name: 'NewMachinePersonal',
            component: NewMachinePersonal
          },
          {//二手贸易个人
            path: 'SecondHandPerson',
            name: 'SecondHandPerson',
            component: SecondHandPerson
          },
          {//二手贸易企业
            path: 'SecondHandEnterprise',
            name: 'SecondHandEnterprise',
            component: SecondHandEnterprise
          },
          {//纺织工厂个人
            path: 'TextileFactoryIndividual',
            name: 'TextileFactoryIndividual',
            component: TextileFactoryIndividual
          },
          {//纺织工厂企业
            path: 'TextileFactoryEnterprise',
            name: 'TextileFactoryEnterprise',
            component: TextileFactoryEnterprise
          },
          {//营销跟进记录
            path: 'FollowUp',
            name: 'FollowUp',
            component: FollowUp
          },
          {//企业中心
            path: 'EnterpriseCentre',
            name: 'EnterpriseCentre',
            component: EnterpriseCentre
          }
          ,
          {//个人中心
            path: 'PersonalCenter',
            name: 'PersonalCenter',
            component: PersonalCenter
          },
          {
            //个人商家详情
            path: 'Personaldetails',
            name: 'Personaldetails',
            component: Personaldetails
          },
          {
            //企业商家详情
            path: 'Enterpriseldetails',
            name: 'Enterpriseldetails',
            component: Enterpriseldetails
          },
          {
            //用户管理
            path: 'User',
            name: 'User',
            component: User
          }, {
            //用户管理
            path: 'UserDetails',
            name: 'UserDetails',
            component: UserDetails
          },
          {//全部商家
            path: 'AllMerchants',
            name: 'AllMerchants',
            component: AllMerchants
          },
          {//虚拟用户
            path: 'VirtualUsers',
            name: 'VirtualUsers',
            component: VirtualUsers
          },
          {//我的团队
            path: 'MyTeam',
            name: 'MyTeam',
            component: MyTeam
          },
          
        ]
      }, {
        path: '/Goods',
        name: 'Goods',
        component: Goods,
        children: [
          {//产品分类
            path: 'Product',
            name: 'Product',
            component: Product
          },
          {//商品发布
            path: 'Release',
            name: 'Release',
            component: Release
          },
          {//商品编辑
            path: 'Release1',
            name: 'Release1',
            component: Release1
          },
          {//商品参数
            path: 'Parameter',
            name: 'Parameter',
            component: Parameter
          },
          {//商品列表
            path: 'List',
            name: 'List',
            component: List
          }
          ,
          {//商品详情
            path: 'Details',
            name: 'Details',
            component: Details
          }
          ,
          {//商品中心
            path: 'Center',
            name: 'Center',
            component: Center
          },
          {//自定义参数
            path: 'parameters',
            name: 'parameters',
            component: parameters
          }
        ]
      }, {
        //订单管理
        path: '/OrderM',
        name: 'OrderM',
        component: OrderM,
        children: [
          {//订单列表
            path: 'OrderList',
            name: 'OrderList',
            component: OrderList
          }, {
            //订单详情
            path: 'OrderDetails',
            name: 'OrderDetails',
            component: OrderDetails
          }, {
            //外贸订单
            path: 'Foreigntrade',
            name: 'Foreigntrade',
            component: Foreigntrade
          }
          , {
            //外贸订单
            path: 'FOrderDetails',
            name: 'FOrderDetails',
            component: FOrderDetails
          }, {//意见合同
            path: 'OpinionContract',
            name: 'OpinionContract',
            component: OpinionContract
          }

        ]
      }
      , {
        //系统设置
        path: '/Settings',
        name: 'Settings',
        component: Settings,
        children: [
          {//参数配置
            path: 'Arguments',
            name: 'Arguments',
            component: Arguments
          }, {
            //友情链接
            path: 'Links',
            name: 'Links',
            component: Links
          },{
            //排名设置
            path: 'Ranking',
            name: 'Ranking',
            component: Ranking
          }, {
            //菜单管理
            path: 'Menu',
            name: 'Menu',
            component: Menu
          }, {
            //站点配置
            path: 'Site',
            name: 'Site',
            component: Site
          }, {
            //站点配置
            path: 'shopseo',
            name: 'shopseo',
            component: shopseo
          }, {
            //站点配置
            path: 'AppVersion',
            name: 'AppVersion',
            component: AppVersion
          }
          , {
            //贸易术语配置
            path: 'INCOTERMS',
            name: 'INCOTERMS',
            component: INCOTERMS
          }
          , {
            //贸易术语编辑
            path: 'editINCOTERMS',
            name: 'editINCOTERMS',
            component: editINCOTERMS
          }, {
            //国家地区管理
            path: 'country',
            name: 'country',
            component: country
          }, {
            //审核模板
            path: 'ReviewTemplate',
            name: 'ReviewTemplate',
            component: ReviewTemplate
          }, {
            //物流管理
            path: 'logistics',
            name: 'logistics',
            component: logistics
          },
          {
            // 站内模版
            path: "SiteTemplate",
            name: "SiteTemplate",
            component: SiteTemplate,
          },
          {
            // 行政区域管理
            path: "RegionManagement",
            name: "RegionManagement",
            component: RegionManagement,
          },
          
        ]
      }, {
        path: '/Operate',
        name: 'Operate',
        component: Operate,
        children: [
          {//用户跟进记录
            path: 'Records',
            name: 'Records',
            component: Records
          },
          {//用户足迹
            path: 'footprint',
            name: 'footprint',
            component: footprint
          },
          {//用户行为
            path: 'Behavior',
            name: 'Behavior',
            component: Behavior
          },
          {//我的举报
            path: 'report',
            name: 'report',
            component: report
          }
          ,
          {//用户反馈
            path: 'feedback',
            name: 'feedback',
            component: feedback
          },
          {//在线留言
            path: 'Message',
            name: 'Message',
            component: Message
          }
          ,
          {//询价中心
            path: 'inquiry',
            name: 'inquiry',
            component: inquiry
          }
          ,
          {//资产处置
            path: 'disposal',
            name: 'disposal',
            component: disposal
          }
          ,
          {//资产处置详情
            path: 'DisposalDetails',
            name: 'DisposalDetails',
            component: DisposalDetails
          }
          ,
          {//我的求购详情
            path: 'PurchaseDetails',
            name: 'PurchaseDetails',
            component: PurchaseDetails
          }
          ,
          {//我的求购
            path: 'purchase',
            name: 'purchase',
            component: purchase
          }
          ,
          {//品牌列表-del
            path: 'Brand',
            name: 'Brand',
            component: Brand
          }
          ,
          {//banner列表
            path: 'Banner',
            name: 'Banner',
            component: Banner
          }
          ,
          {//产品广告位
            path: 'Space',
            name: 'Space',
            component: Space
          }
          ,
          {//广告位列表
            path: 'advertising',
            name: 'advertising',
            component: advertising
          },
          {//客服管理
            path: 'CustomerService',
            name: 'CustomerService',
            component: CustomerService
          },
          {//客户分配
            path: 'CustomerSllocation',
            name: 'CustomerSllocation',
            component: CustomerSllocation
          },
          {//在线客服
            path: 'Online',
            name: 'Online',
            component: Online
          },
           {//招聘管理
            path: 'Recruiting',
            name: 'Recruiting',
            component: Recruiting
          },
           {//营销活动
            path: 'MarketingActivities',
            name: 'MarketingActivities',
            component: MarketingActivities
          },
          

        ]
      }, {
        path: '/Content',
        name: 'Content',
        component: Content,
        children: [
          {//内容分类
            path: 'Classify',
            name: 'Classify',
            component: Classify
          },
          {//内容列表
            path: 'Listing',
            name: 'Listing',
            component: Listing
          },
          {//公告列表
            path: 'Notice',
            name: 'Notice',
            component: Notice
          },
          {//内容b编辑或新增
            path: 'Category',
            name: 'Category',
            component: Category
          },
          {//帮助中心回收站
            path: 'RecycleBinS',
            name: 'RecycleBinS',
            component: RecycleBinS
          },
          {//内容管理
            path: 'Contents',
            name: 'Contents',
            component: Contents
          },
          

        ]
      }, {
        //权限管理列表
        path: '/Management',
        name: 'Management',
        component: Management,
        children: [
          {//操作日志
            path: 'Diary',
            name: 'Diary',
            component: Diary
          },
          {//管理员列表
            path: 'Managers',
            name: 'Managers',
            component: Managers
          },
          {//组织管理列表
            path: 'Organizational',
            name: 'Organizational',
            component: Organizational
          },
          {//角色列表
            path: 'Role',
            name: 'Role',
            component: Role
          },
        ]
      },
      {
        path: '/Userd',//用户管理
        name: 'Userd',
        component: Userd,
        children: [
          {//企业相册--相册视频分类
            path: 'AlbumVideo',
            name: 'AlbumVideo',
            component: AlbumVideo
          },
          {//经销商列表
            path: 'DealerList',
            name: 'DealerList',
            component: DealerList
          },
          {//用户设置
            path: 'UserSettings',
            name: 'UserSettings',
            component: UserSettings
          },
          {//自定义品牌参数
            path: 'Custom',
            name: 'Custom',
            component: Custom
          }
        ]
      },
      {
        path: '/Financial',//财务管理
        name: 'Financial',
        component: Financial,
        children: [
          {//公司抬头
            path: 'CompanyTitle',
            name: 'CompanyTitle',
            component: CompanyTitle
          },
          {//收款账号
            path: 'PaymentAccount',
            name: 'PaymentAccount',
            component: PaymentAccount
          },
          {//收款账号
            path: 'PaymentAddition',
            name: 'PaymentAddition',
            component: PaymentAddition
          },
          {//单证类型
            path: 'DocumentType',
            name: 'DocumentType',
            component: DocumentType
          }

        ]
      },

    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
//配置路由全局前置守卫导航
router.beforeEach((to, from, next) => {
  //判断进入的路由解密是否需要登录
  if (to.matched.some(item => item.meta.isLogin)) {
    //判断是否登录
    if (localStorage.getItem('token')) {
      if (store.getters.menuList.length === 0) {
        store.dispatch('GetMenuList').then(() => {
          next()
        }).catch(err => {
          store.dispatch('LogOut')
          next('/login')
        })
      } else {
        next()
      }

    } else {
      store.dispatch('LogOut')
      next('/login')
    }
  } else {
    next()
  }
}
)
export default router
