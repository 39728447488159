<template>
    <poop v-show="isOpenShow" class="zindex"  >
      <div class="w700 pr">
        <div class="pa wtrp1" @click="closePopup">
          <i class="el-icon-circle-close cp f20
"></i>
        </div>
        <div class="flex jcc aic">
          <div class="f16 textac">
            搜索结果
          </div>
        </div>
        <div class="  wtrp1">
            <el-table
    :data="tableData"
    style="width: 100%"
   >
    <el-table-column
      fixed
      prop="company_name"
      label="公司名称"
     >
    </el-table-column>
    <el-table-column
      prop="name"
      label="所在菜单"
      >
      <template slot-scope="{ row }">{{ row.admin_id>0?'客户数据':'公海数据' }}</template>
      
    </el-table-column>
    <el-table-column
      prop="department_name"
      label="所在部门"
      >
    </el-table-column>
    <el-table-column
      prop="admin_nick"
      label="跟进人"
     >
    </el-table-column>
    <el-table-column
      prop="address"
      label="是否注册平台"
     >
     <template slot-scope="{ row }">{{ row.is_register==1?'已注册':'未注册' }}</template>
    </el-table-column>
    <el-table-column
    
      label="操作"
      width="60">
      <template slot-scope="{ row }">
        <div class="cp" @click="goto(row)">
          
          <i v-if="row.is_goto==1">前往</i>
          <!-- {{ row.is_register==1?'已注册':'未注册' }} -->
        </div>
        
      </template>
    </el-table-column>
  </el-table>
  <div class="flex jcc aic pt10">
    <el-pagination
    background

      @current-change="handleCurrentChange"
    layout="prev, pager, next"
    :total="total">
  </el-pagination>
  </div>

        </div>
    </div>
    </poop>
  </template>
  
  <script>
  export default {
    name: "spop",
   
    data() {
      let that = this;
      return {
        isOpenShow:false,
        sname:"",
        page:1,
        tableData:[],
        total:0,
      };
    },
    mounted() {

    },
  
    methods: {
        isOpen(el){
            this.isOpenShow=true;
            this.sname=el
     
            this.search()
        },
      //成功提示
      success(v) {
        this.$message({
          type: "success",
          message: v,
        });
      },
  
      info(v) {
        this.$message({
          type: "error",
          message: v,
        });
      },
      goto(el){
      
        this.$root.$emit('ffopenSea',el)
      },
      async saveList() {
        this.disabled = true;
        let data = {
          id: this.company_id,
        };
        
  
        await this.$axios
          .post(this.webAPI + "/CrmPoolDetail/edit", data)
          .then((res) => {
            if (res.msg == "操作成功") {
              this.success("操作成功");
              
            } else {
              this.info(res.msg)
        
            }
          });
      },
      async search() {
  
  
        await this.$axios
          .get(this.webAPI + `/CrmPool/search?val=${this.sname}&size=10&page=${this.page}`)
          .then((res) => {
            if (res.msg == "操作成功") {
              
                this.tableData=res.data.data
                this.total=res.data.total
            } else {
              this.info(res.msg)
            }
          });
      },
      closePopup(){

        this.isOpenShow=false;
      },
    
      handleCurrentChange(el){
    
        this.page=el
        this.search()
      },
    },
    
  };
  </script>
  <style scoped lang="less">
  .w700 {
    width: 900px;
    // height: 650px;
    padding-bottom: 20px;
  }
  
  .wtrp1 {
    top: -10px;
    right: 0;
  }
  .wtrp2 {
    position: fixed;
  }
  .f20{
    font-size: 20px;
  }
 
  </style>