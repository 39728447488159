<template>
    <!-- 组件根元素，根据 visible 属性控制显示与隐藏 -->
    <div v-if="visible" class="loading-overlay">
      <div class="loading-spinner">
        <!-- 这里可以使用一个 SVG 图标作为加载动画 -->
        <!-- <svg class="spinner" viewBox="0 0 50 50">
          <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg> -->
        <el-progress type="circle" :percentage="curPercentage" :width="100" class="progressCircle"></el-progress>
        <!-- 加载提示文字 -->
        <!-- <p>加载中，请稍候...</p> -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LoadingComponent',
    props: {
      // visible 属性用于控制组件的显示与隐藏，默认值为 false
      visible: {
        type: Boolean,
        default: false
      },
      curPercentage: {
        type: Number,
        default: 0
      }, 
    }
  };
  </script>
  
  <style scoped>
  .loading-overlay {
    /* 固定定位，覆盖整个页面 */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* 弹性布局，居中显示内容 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loading-spinner {
    /* 白色背景，圆角边框 */
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .spinner {
    /* 设置 SVG 图标的大小和动画效果 */
    animation: rotate 2s linear infinite;
    width: 50px;
    height: 50px;
  }
  
  .path {
    /* 图标路径的颜色和动画效果 */
    stroke: #007BFF;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
  </style>